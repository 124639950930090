import React from "react"

const IntroText = () => (
  <div>
    <h2>¡HOLA! I'M JEN & I LOVE TO CODE!</h2>
    <p>
      You can keep on scrolling to read more about me or visit one of my social media spaces.
    </p>
  </div>
)

export default IntroText